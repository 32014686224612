<template>
	<div class="all drag" v-draw id="RqOnlineService">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_客服系统</div>
				<div class="zhuangshiyb"></div>
			</div>
			<div class="icon" @click="closeCompon">
			  
			</div>
		</div>
			
		<div class="company">
			<div class="company_title">
				和隆优化客户服务系统
			</div>
		</div>
		<div class="content">
			<div class="message">和隆信息</div>
			<div class="company_messages_total flex">
				<div class="company_messages_left">
					<div class="flex">
						<div class="words">客户服务热线：</div>
						<div class="num">400-8811-519</div>
					</div>
					<div class="flex">
						<div class="words">本项目技术支持热线：</div>
						<div class="num">18001170000</div>
					</div>
					<div class="flex">
						<div class="words">项目签订时间：</div>
						<div class="num"> <span>2019/6/21</span> </div>
					</div>
					<div class="flex">
						<div class="words">项目开始实施时间：</div>
						<div class="num">2021/7/21</div>
					</div>
					<div class="flex">
						<div class="words">项目验收时间：</div>
						<div class="num">2021/8/21</div>
					</div>
				</div>
				<div class="company_messages_right">
					<div class="location">
						<div class="start">
							<div class="start_words" @click="openTeam(1)">启动远程协助系统</div>
						</div>
						<div class="stop">
							<div class="stop_words" @click="openTeam(2)">停止远程协助系统</div>
						</div>
					</div>
				</div>
			</div>
			<div class="message">客户信息</div>
			<div class="customer_messages_total flex">
				<div class="customer_messages_left">
					<div class="boiler_info">
						<div class="boiler_info_word">锅炉信息</div>
					</div>
					<div class="specific_info">
						<div class="flex">
							<div class="left_words">锅炉型号：</div>
							<div class="left_num">CG-220/9.8</div>
						</div>
						<div class="flex">
							<div class="left_words">制造单位：</div>
							<div class="left_num">***锅炉厂</div>
						</div>
						<div class="flex">
							<div class="left_words">投用日期：</div>
							<div class="left_num">顶燃式</div>
						</div>
						<div class="flex">
							<div class="left_words">层数：</div>
							<div class="left_num">两层</div>
						</div>
						<div class="flex">
							<div class="left_words">煤气：</div>
							<div class="left_num">高炉煤气</div>
						</div>
						<div class="flex">
							<div class="left_words">煤气详情：</div>
							<div class="left_num">高炉转炉混合</div>
						</div>
						<div class="flex">
							<div class="left_words">鼓风：</div>
							<div class="left_num">挡板、变频</div>
						</div>
						<div class="flex">
							<div class="left_words">引风：</div>
							<div class="left_num">挡板、变频</div>
						</div>
						<div class="flex">
							<div class="left_words">DCS型号：</div>
							<div class="left_num">科远NT6000</div>
						</div>
						<div class="flex">
							<div class="left_words">DCS版本：</div>
							<div class="left_num">NT6000&nbsp;&nbsp;V3</div>
						</div>
					</div>
					
				</div>
				<div class="customer_messages_right">
					<div class="person_info">
						<div class="person_info_word">项目相关负责人信息</div>
					</div>
					<div class="right_up">
						<div class="flex">
							<div class="right-word">项目总负责人：</div>
							<div class="right_num">张三，18001179000；总公司环保处处长负责项目协调和关键时间节点把控；</div>
						</div>
						<div class="flex">
							<div class="right-word">工艺总负责人：</div>
							<div class="right_num">李四，18001179000；炼铁厂电仪科科长负责项目工艺流程和调试把关；</div>
						</div>
						<div class="flex">
							<div class="right-word">自动化负责人：</div>
							<div class="right_num">王五，18001179000；炼铁厂自动化处长负责项目DCS程序修改，通讯对接等；</div>
						</div>
						<div class="flex">
							<div class="right-word">1#控制室电话：</div>
							<div class="right_num">010-82926605</div>
						</div>
					</div>
					<div class="right_down">
						<div class="right_down_left">
							<div class="flex">
								<div class="right-word1">大班长：</div>
								<div class="right_num1">张三，18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word1">甲班：</div>
								<div class="right_num1">王五，18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word1">乙班：</div>
								<div class="right_num1">李四，18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word1">丙班：</div>
								<div class="right_num1">王五，18001179000</div>
							</div>
						</div>
						<!-- <div class="right_down_right">
							<div class="flex">
								<div class="right-word2">乙班：</div>
								<div class="right_num2">李四，18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word2">丙班：</div>
								<div class="right_num2">王五，18001179000</div>
							</div>
						</div> -->
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

		<script>	
			export default {
			    name: "RqOnlineService",
			     props:{
			        infoList:{
			            type:Object,
			            default: {} // 默认值
			        }
			    },
			 components:{},
			    data: () => {
			  return {
			      projectData:'',
			      grouptime:null,
			      spotArr:[],
			      authInfo:[],
			      UserInfo:[],
			  }
			        
			 },
			  created(){
			     this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			     this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			       this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			  this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			  },
			  methods:{
				// 打开temaview
				openTeam(key){
					if(key== 1){
						 this.$message.success('请打开TeamViewer软件，获得远程协助');
					}else{
					this.$message.error('请打开TeamViewer软件，停止远程协助');	
					}
				},
			    closeCompon(){
			
			      this.$emit('sendStatus', 'RqOnlineService',false)
			    },
			    } 
			}
	</script>


<style lang="scss" scoped>
	#RqOnlineService{
		width: 36vw;
		height: 60vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;
		margin-left: 32vw;
		margin-top: 16vh;
		.title-hang {
		  width: 30vw;
		  height: 3vh;
		  font-family: MicrosoftYaHei;
		  font-size: 1vw;
		  font-weight: normal;
		  font-stretch: normal;
		  line-height: 2vh;
		  letter-spacing: 0vh;
		}
		 .zhuangshizb {
		  width: 9.5vw;
		  height: 1vh;
		  background-image: url("~@/assets/images/rfl_evaluate06.png");
		  background-size: 100% 100%;
		  margin-left: 4vw;
		  margin-top: 2vh;
		}
		.zhuangshizj {
			width: 16vw;
		    height: 3vh;
		    font-family: MicrosoftYaHei;
		  	font-size: 1vw;
		  	font-weight: normal;
		  	font-stretch: normal;
		  	line-height: 2.8vh;
		  	letter-spacing: 0vh;
		  	color: #0ef7ff;
			margin-top:1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left:1vw;
		}
		.zhuangshiyb {
		  width: 9.5vw;
		  height: 1vh;
		  background-image: url("~@/assets/images/rfl_evaluate04.png");
		  background-size: 100% 100%;
		  margin-left: 1vw;
		  margin-top: 2vh;
		}
		.icon{
			width: 2vw;
			height: 3.5vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 3vw;
			cursor: pointer;

		}
		.company{
			width: 36vw;
			height: 2vh;
			margin-top: 3vh;
			.company_title{
				font-family: PingFang-SC-Regular;
				font-size: 1.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}
		.content{
			margin-top: 2vh;
			margin-left: 1vw;
			width: 34vw;
			height: 46vh;
			border:1px solid #00e4ff;
			box-sizing: border-box;
			.message{
				height: 4vh;
				text-align: center;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vh;
				color: #d5fffe;
				border-bottom:1px solid #00e4ff;
				box-sizing: border-box;
			}
			.company_messages_total{
				height: 11vh;
				border-bottom: 1px solid #00e4ff;
				font-family: PingFang-SC-Regular;
				font-size: 0.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;
				.company_messages_left{
					width: 17vw;
					height: 11vh;
					border-right:1px solid #00e4ff;
					.words{
						width: 10vw;
						text-align: right;
						color: #8aeaff;
					}
					.num{
						margin-left: 0.5vw;
						width: 6.5vw;
						color: #ffffff;
						cursor: pointer;
					}
				}
				.company_messages_right{
					font-family: PingFang-SC-Regular;
					font-size: 0.5vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vh;
					letter-spacing: 0vh;
					.location{
						margin-left: 5vw;
						margin-top: 2.7vh;
						.start{
							width: 8vw;
							height: 3vh;
							background-color: #00ff8a;
							border: solid 0vh #00fc98;
							.start_words{
								color: #005d8f;
								text-align: center;
							}
						}
						.stop{
							width: 8vw;
							height: 3vh;
							background-color: #003b5b;
							margin-top: 0.3vw;
							.stop_words{
								color: #a3cbe0;
								text-align: center;
							}
						}
					}
				}
			}
			.customer_messages_total{
				font-family: PingFang-SC-Regular;
				font-size: 0.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 1vw;
				letter-spacing: 0vh;
				.customer_messages_left{
					width: 14vw;
					height: 26.8vh;
					border-right:1px solid #00e4ff;
					.boiler_info{
						height: 1vh;
						.boiler_info_word{
							color: #d5fffe;
							text-align: center;
							margin-top: 2vh;
							cursor: pointer;
						}
					}
					.specific_info{
						margin-top: 1vh;
						.left_words{
							width: 6vw;
							color: #8aeaff;
							text-align: right;
						}
						.left_num{
							width: 8vw;
							color: #ffffff;
							cursor: pointer;
						}
					}
				}
				.customer_messages_right{
					font-size: 0.6vw;
					line-height: 1.5vh;
					.person_info{
						height: 1vh;
						.person_info_word{
							color: #d5fffe;
							text-align: center;
							margin-top: 2vh;
							cursor: pointer;
						}
					}
					.right_up{
						margin-top: 1.5vh;
						margin-left: 0vw;
						.right-word{
							width: 7vw;
							color: #8aeaff;
							text-align: right;
						}
						.right_num{
							width: 12vw;
							color: #ffffff;
							cursor: pointer;
						}
					}
					.right_down{
						margin-top: 0.5vw;
						.right_down_left{
							margin-left: 1vw;
							.right-word1{
								width: 5vw;
								color: #8aeaff;
								text-align: right;
							}
							.right_num1{
								width: 9vw;
								color: #ffffff;
								cursor: pointer;
							}
						}
						// .right_down_right{
						// 	margin-left: 0.5vw;
						// 	.right-word2{
						// 		width: 3vw;
						// 		color: #8aeaff;
						// 		text-align: right;
						// 	}
						// 	.right_num2{
						// 		width: 8vw;
						// 		color: #ffffff;
						// 	}
						// }
					}
				}
				
			}
		}
		
		
		
	}
	
</style>
